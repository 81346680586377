<template>
    <div class="container">
        <div v-if="loadingPage" class="d-flex justify-content-center mt-5 mb-5">
            <Preloader/>
        </div>
        <div v-else>
            <ConfirmDialog group="confirmCloseStatementDialog"></ConfirmDialog>
            <div v-if="success">
                <h4 class="mt-4 text-center">Журнал учета успеваемости ({{ratingTypes[type]}})</h4>
                <h5 class="mt-4 text-danger" v-if="isClosed">Ведомость закрыта</h5>
                <div class="mt-4 mb-2">
                    <div class="row">
                        <div class="col-md-2">
                            Название дисциплины
                        </div>
                        <div class="col-md-10">
                            {{journalRating?.discipline?.name}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            Курс
                        </div>
                        <div class="col-md-10">
                            {{journalRating?.discipline?.study_course}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            Семестер
                        </div>
                        <div class="col-md-10">
                            {{journalRating?.discipline?.semester}}
                        </div>
                    </div>

                    <div v-if="journalRating?.teacher?.lecture_user_id==journalRating?.teacher?.seminar_user_id">
                        <div class="row">
                            <div class="col-md-2">
                                Преподаватель
                            </div>
                            <div class="col-md-10">
                                {{journalRating?.teacher?.lecture_lastname}}
                                {{journalRating?.teacher?.lecture_firstname}}
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row">
                            <div class="col-md-2">
                                Лектор
                            </div>
                            <div class="col-md-10">
                                {{journalRating?.teacher?.lecture_lastname}}
                                {{journalRating?.teacher?.lecture_firstname}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                Практик
                            </div>
                            <div class="col-md-10">
                                {{journalRating?.teacher?.seminar_lastname}}
                                {{journalRating?.teacher?.seminar_firstname}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            Moodle курс
                        </div>
                        <div class="col-md-10">
                            <a :href="'https://moodle.uib.kz/course/view.php?id='+ journalRating?.discipline?.mdl_course_id"
                               target="_blank">
                                {{journalRating?.discipline?.course_name}}
                            </a>
                        </div>
                    </div>
                    <div class="row mt-2 mb-4">
                        <div class="col-md-2">
                            Группы
                        </div>
                        <div class="col-md-6">
                            <select class="form-select" v-model="selectedGroup">
                                <option v-for="(group, groupIndex) in [{group_id: 0, group_name: 'Все'}, ...groups]"
                                        :key="groupIndex" :value="group.group_id">
                                    {{group.group_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">Группа</th>
                            <th scope="col">Штрих-код</th>
                            <th scope="col">Ф.И.О</th>
                            <th scope="col">{{ratingTypes[type]}}</th>
                            <th scope="col" v-if="!isClosed && isLectureTeacher && (journalRating.discipline.created_at > 1693504800 && journalRating.discipline.created_at < 1704045540)">Поставить оценку</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(student, studentIndex) in studentsFilter" :key="studentIndex">
                            <th scope="row">{{studentIndex+1}}</th>
                            <td>{{student?.group_name}}</td>
                            <td>{{student?.barcode}}</td>
                            <td>{{student?.last_name+' '+student?.first_name}}</td>
                            <td>{{student[type]}}</td>
                            <td v-if="!isClosed && isLectureTeacher && (journalRating.discipline.created_at > 1693504800 && journalRating.discipline.created_at < 1704045540)">
                              <input class="form-control" type="number"
                                     :value="getStudentVsk(student.barcode)"
                                     max="100" min="0" placeholder="Оценка"
                                     @change="changeVsk(student.student_ratings_id, $event)">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-center mt-4 mb-4" v-if="!isClosed&&isLectureTeacher&&!closeStatus">

                  <div v-if="(journalRating.discipline.created_at > 1693504800 && journalRating.discipline.created_at < 1704045540)">
                    <button class="btn btn-primary m-1" @click="saveVskGrades">Сохранить оценки</button>
                    <button v-if="!isClosed" class="btn btn-primary m-1" @click="closeVsk">Закрыть ВСК</button>
                  </div>
                  <div v-else>
                    <button class="btn btn-primary m-1" @click="importGradesFromMoodle">Обновить оценки</button>
                    <button class="btn btn-primary m-1" @click="confirmCloseStatement">Закрыть ведомость у всех групп</button>
                  </div>


                </div>
            </div>
            <h4 v-else class="text-center mt-4">Курс не найден</h4>


        </div>
    </div>

</template>

<script>

    import {mapActions, mapState} from "vuex";
    import {getCookie} from "@/utils/helpers/cookies.helpers";

    export default {
        name: "JournalRating",
        data() {
            return {
                mdl_course_id: +this.$route.query.mdl_course_id || 0,
                type: this.$route.query.type || '',
                loadingPage: true,
                success: false,
                closeStatus: false,
                ratingTypes: {
                    vsk1: 'ВСК 1',
                    vsk2: 'ВСК 2',
                    // exam: 'Экзамен',
                },
                groups: [],
                selectedGroup: 0,
                studentVsk: []
            }
        },
        computed: {
            ...mapState('journalRating', ['journalRating']),
            isClosed() {
                return this.journalRating.students.some(i => i['status_' + this.type] == 1)
            },
            isLectureTeacher() {
                return this.journalRating?.teacher?.lecture_user_id == getCookie('USER_ID')
            },
            studentsFilter() {
                if (this.selectedGroup) {
                    return this.journalRating.students.filter(i => i.group_id == this.selectedGroup)
                }
                return this.journalRating.students
            },

        },

        methods: {
            ...mapActions('journalRating', [
                'GET_STATEMENT_BY_MDL_COURSE_ID',
                'GET_TEACHER_BY_MDL_COURSE_ID',
                'GET_DISCIPLINE_BY_MDL_COURSE_ID',
                'IMPORT_GRAGES_FROM_MOODLE',
                'CLOSE_STATEMENT',
                'SAVE_VSK_GRADES',
                'CLOSE_VSK'
            ]),
          getStudentVsk(barcode){
              let student = this.journalRating.students.find(i=>i.barcode == barcode)
              let vsk = student[this.type]
              return vsk
          },
            async importGradesFromMoodle() {
                await this.IMPORT_GRAGES_FROM_MOODLE({mdl_course_id: this.mdl_course_id, type: this.type})
                await this.GET_STATEMENT_BY_MDL_COURSE_ID(this.mdl_course_id)
                this.$message({text: 'Оценки успешно импортированы'})
            },
            async closeStatement() {
                this.CLOSE_STATEMENT({mdl_course_id: this.mdl_course_id, type: this.type})
                await this.GET_STATEMENT_BY_MDL_COURSE_ID(this.mdl_course_id)
                this.closeStatus = true
                this.$message({text: `Ведомость ${this.ratingTypes[this.type]} закрыта`})
            },
            confirmCloseStatement() {
                this.$confirm.require({
                    group: 'confirmCloseStatementDialog',
                    acceptLabel: 'Закрыть',
                    rejectLabel: 'Отмена',
                    message: 'Вы точно хотите закрыть ведомость?',
                    header: 'Ведомость',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.closeStatement()
                    }
                });
            },
          changeVsk(student_ratings_id, e) {
            let vsk = e.target.value
            let student = this.journalRating.students.find(i => i.student_ratings_id == student_ratings_id)
            student[this.type] = vsk
            student['is_vsk_change'] = 1

            console.log(student, 'changeVsk')
          },

          async saveVskGrades() {

            let form = this.journalRating.students.filter(i => i.is_vsk_change == 1)

            console.log(form, 'saveVskGrades')
            const res = await this.SAVE_VSK_GRADES({form, type: this.type})
            if (res) {
              await this.GET_STATEMENT_BY_MDL_COURSE_ID(this.mdl_course_id)
              this.$message({title: 'Оценка', text: 'Оценки успешно сохранены'});
            } else {
              this.$error({title: 'Оценка', text: 'Произошла ошибка'})
            }
          },
          async closeVsk() {
            const res = await this.CLOSE_VSK({mdl_course_id: this.mdl_course_id, type: this.type})
            console.log(this.mdl_course_id, 'this.mdl_course_id')
            console.log(this.type, 'this.type')
            if (res) {
              await this.GET_STATEMENT_BY_MDL_COURSE_ID(this.mdl_course_id)
              this.$message({title: 'Ведомость', text: 'Ведомость успешна закрыта'});
            } else {
              this.$error({title: 'Ведомость', text: 'Произошла ошибка'})
            }
          }
        },

        async mounted() {
            if (this.ratingTypes[this.type]) {
                await this.GET_DISCIPLINE_BY_MDL_COURSE_ID(this.mdl_course_id)
                if (this.journalRating?.discipline?.mdl_course_id) {
                    await this.GET_STATEMENT_BY_MDL_COURSE_ID(this.mdl_course_id)
                    await this.GET_TEACHER_BY_MDL_COURSE_ID(this.mdl_course_id)
                    const groupsObj = {}
                    this.journalRating.students.forEach(i => {
                        groupsObj[i.group_id] = i
                    })
                    this.groups = Object.values(groupsObj).map(i => ({
                        group_id: i.group_id,
                        group_name: i.group_name
                    }))
                    console.log(this.groups)
                    this.success = true
                }
            }
            this.loadingPage = false
        }

    }
</script>

<style scoped>

</style>
